import React from 'react'
import { Table, Row, Col, Tooltip, Input } from 'antd'
import {
  LockOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'

import { BoostText, UpArrow, DownArrow, ArrowInput } from '../../../../../components/boost'

import { overridePrimary, framed, overridePerms, overrideBackground, overrideColor } from '../../../../../constants/partner'
import isNull from '../../../../../utils/isNull'
import { getNBAGPPValue, getShowdownGPPValue } from '../../../../../utils/gpp-value'
import getProjPtsForMins from '../../../../../utils/get-projPts-for-mins'

import { StyledTable} from '../../../../../components/shared-styles'
import { getTeamImg } from '../../../../../utils/team-images-loader'

const PlayerText = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${framed ? overridePrimary : '#1d3557'};
  &:hover {
    color: #1890ff;
  }
`

const ClickableLock = styled(LockOutlined)`
  :hover {
    color: ${overridePrimary};
  }
`
const ClickableRemove = styled(CloseOutlined)`
  :hover {
    color: ${overridePrimary};
  }
`

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const VEGAS_ODDS = true

const playerDetailsCols =  (setWidths, selectPlayerStats, isPlayerLockValid, setHandbuildError, expOnChange) => ({
  title: 'Player Details',
  key: 'playerDetails',
  children: [
    {
      title: '',
      key: 'lockUnlock',
      width: 60,
      render: (_, row) => {
        return (
          <Row>
            <Col lg={12} md={12} sm={12} xs={24}>
              <ClickableLock
                style={{
                  color: Number(row.MinExp) === 100 ? overridePrimary : overrideColor
                }}
                onClick={() => {
                  const available = isPlayerLockValid(row)

                  if (!available) {
                    setHandbuildError(`Unable to add ${row.Name}. Too many players at this position.`)
                  } else {
                    _lockRow(row, expOnChange)
                  }
                }}
              />
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <ClickableRemove onClick={() => _clearRow(row, expOnChange)}/>
            </Col>
          </Row>
        )
      }
    },
    {
      title: 'Player Name',
      dataIndex: 'Name',
      key: 'playerName',
      width: setWidths ? 200 : undefined,
      render: (text, row) => {
        return (
          <PlayerText onClick={() => {}}>
            {text}
          </PlayerText>
        )
      }
    },
    {
      title: 'Salary',
      dataIndex: 'Salary',
      key: 'salary',
      defaultSortOrder: 'descend',
      sorter: (a, b) => Number(a.Salary - b.Salary),
    },
    {
      title: 'Pos',
      dataIndex: 'Position',
      key: 'position',
      className: 'divide',
      width: setWidths ? 60 : undefined,
    },
  ]
})

const odds_cols = VEGAS_ODDS ? (
  [{
    title: (
      <Tooltip placement="top" title={"Projected total points for the game"}>
        <span>OU</span>
      </Tooltip>
    ),
    dataIndex: 'OU',
    width:  80,
    key: 'ou',
    sorter: (a, b) => Number(a.OU) - Number(b.OU),
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected amount team will win or lose by (favored team is negative)"}>
        <span>Spread</span>
      </Tooltip>
    ),
    dataIndex: 'Spread',
    key: 'spread',
    sorter: (a, b) => Number(a.Spread) - Number(b.Spread)
  },
  {
    title: (
      <Tooltip placement="top" title={"Projected total points for the team. For DST, it shows the total for the opposing team (lower Imp Pts is better for a DST)"}>
        <span>Imp Pts</span>
      </Tooltip>
    ),
    dataIndex: 'ImpPts',
    key: 'currImpPt',
    sorter: (a, b) => Number(a.ImpPts) - Number(b.ImpPts),
    className: 'divide'
  }]
) : []

const teamAndGameInfoCols = (setWidths) => ({
  title: 'Game Details',
  key: 'teamGameInfo',
  children: [
    {
      title: 'Team',
      dataIndex: 'TeamAbbrev',
      key: 'teamAbbrev',
      width: setWidths ? 60 : undefined,
      render: (text, row) => {
        try {
          return (
            getTeamImg('nba', text)
          )
        } catch (e) {
          console.log(text)
          console.log(row)
        }
      }
    },
    {
      title: 'Opp',
      dataIndex: 'Opp',
      key: 'opp',
      width: setWidths ? 60 : undefined,
      render: (text, row) => {
        try {
          return (
            getTeamImg('nba', text)
          )
        } catch (e) {
          console.log(text)
          console.log(row)
        }
      }
    },
    {
      title: 'Ven.',
      dataIndex: 'Venue',
      key: 'venue',
      width: setWidths ? 50 : undefined,
      className: 'divide'
    },
    ...odds_cols
  ]
})

const UserProjInput = styled(Input)`
  width: 60px;
`

const _onUserProjChange = (row, e, _onChange) => {
  let projPts = e.target.value
  if (projPts === ".")
    projPts = "0."
  // Keep track of the original Proj Pts
  const HouseProjPts = (row.HouseProjPts === undefined || row.HouseProjPts === null) ? row.ProjPts : row.HouseProjPts
  const HouseProjMins = (row.HouseProjMins === undefined || row.HouseProjMins === null) ? row.ProjMins : row.HouseProjMins
  // const HouseProjMins = row.HouseProjMins ? row.HouseProjMins : row.ProjMins
  let ProjPtsAdjusted = getProjPtsForMins(HouseProjPts, projPts, HouseProjMins, row.UserMins)

  let _row
  if (projPts === '') {
    _row = {
      ...row,
      UserProj: '',
      ProjPts: ProjPtsAdjusted || HouseProjPts,
      HouseProjPts,
      ProjPtsAdjusted
    }
  } else {
    _row = {
      ...row,
      UserProj: projPts,
      ProjPts: projPts,
      HouseProjPts,
      ProjPtsAdjusted
    }
  }

  _onChange(_row)
}

const _onUserMinsChange = (row, e, _onChange) => {
  let projMins = e.target.value
  if (projMins === ".")
  projMins = "0."
  // Keep track of the original Proj Pts
  const HouseProjPts = (row.HouseProjPts === undefined || row.HouseProjPts === null) ? row.ProjPts : row.HouseProjPts
  const HouseProjMins = (row.HouseProjMins === undefined || row.HouseProjMins === null) ? row.ProjMins : row.HouseProjMins

  let ProjPtsAdjusted = getProjPtsForMins(HouseProjPts, row.UserProj, HouseProjMins, projMins)

  let _row
  if (projMins === '') {
    _row = {
      ...row,
      UserMins: '',
      ProjMins: HouseProjMins,
      HouseProjMins,
      ProjPts: HouseProjPts,
      ProjPtsAdjusted: null,
      HouseProjPts
    }
  } else {
    _row = {
      ...row,
      UserMins: projMins,
      ProjMins: projMins,
      HouseProjMins,
      ProjPts: ProjPtsAdjusted,
      ProjPtsAdjusted,
      HouseProjPts
    }
  }

  _onChange(_row)
}

const _onUserOwnChange = (row, e, _onChange) => {
  let projOwn = e.target.value
  if (projOwn === ".")
    projOwn = "0."
  // Keep track of the original Ownership
  const HouseOwn = row.HouseOwn ? row.HouseOwn : row.ProjOwn

  let _row
  if (projOwn === '') {
    if (HouseOwn) {
      _row = {
        ...row,
        UserOwn: '',
        ProjOwn: HouseOwn,
        HouseOwn
      }
    } else {
      _row = row
    }
  } else {
    _row = {
      ...row,
      UserOwn: projOwn,
      ProjOwn: projOwn,
      HouseOwn
    }
  }

  _onChange(_row)
}

const playerProjectionCols = (userProjOnChange, permissions, site, showdown=false, authorizedSport=false) => {
  const getPPMFromRow = (row) => {
    if (Number(row.ProjMins) < 1) {
      return 0
    }
    if (authorizedSport) {
      return (
        (Number(row.ProjPts) / Number(row.ProjMins))
      )
    } else {
      return <Lock />
    }
  }

  return ({
  title: 'Player Projections',
  key: 'playerProjections',
  children: [
    {
      title: (
        <Tooltip placement="top" title={"Projected minutes played"}>
          <span>Min</span>
        </Tooltip>
      ),
      key: 'projMins',
      sorter: (a, b) => Number(a.ProjMins) - Number(b.ProjMins),
      render: (_, row) => {
        if (authorizedSport) {
          const HouseProjMins = (row.HouseProjMins === undefined || row.HouseProjMins === null) ? row.ProjMins : row.HouseProjMins
          return (
            Number(HouseProjMins).toFixed(2)
          )
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Overwrite our projected points here"}>
          <span>User Min</span>
        </Tooltip>
      ),
      dataIndex: 'UserMins',
      key: 'UserMins',
      render: (text, row) => {
        return (<UserProjInput onChange={(e) => _onUserMinsChange(row, e, userProjOnChange)} value={isNull(text) ? '' : text} />)
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Player's projected point total"}>
          <span>Proj Pts</span>
        </Tooltip>
      ),
      key: 'dKPts',
      sorter: (a, b) => Number(a.HouseProjPts || a.ProjPts) - Number(b.HouseProjPts || b.ProjPts),
      render: (_, row) => {
        if (authorizedSport) {
          const HouseProjPts = row.ProjPtsAdjusted || ((row.HouseProjPts === undefined || row.HouseProjPts === null) ? row.ProjPts : row.HouseProjPts)
          return Number(
            HouseProjPts
          ).toFixed(2)
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Overwrite our projected points here"}>
          <span>User Proj</span>
        </Tooltip>
      ),
      dataIndex: 'UserProj',
      key: 'userProj',
      render: (text, row) => {
        return (<UserProjInput onChange={(e) => _onUserProjChange(row, e, userProjOnChange)} value={isNull(text) ? '' : text} />)
      }

    },
    {
      title: (
        <Tooltip placement="top" title={"Projected Points / Minutes"}>
          <span>PPM</span>
        </Tooltip>
      ),
      key: 'ppm',
      sorter: (a, b) => getPPMFromRow(b) - getPPMFromRow(a),
      render: (_, row) => {
        try {
          return getPPMFromRow(row).toFixed(2)

        } catch {
          console.log(row)
          return 0
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Projected points / Salary * 1000"}>
          <span>Proj Value</span>
        </Tooltip>
      ),
      key: 'projval',
      sorter: (a, b) => (Number(a.ProjPts) / Number(a.Salary) * 1000) - (Number(b.ProjPts) / Number(b.Salary) * 1000),
      render: (_, row) => {
        let multiplier = 1000
        if (site === 'ya')
          multiplier = 10

        if (authorizedSport) {
          return (
            (Number(row.ProjPts) / Number(row.Salary) * multiplier).toFixed(2)
          )
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Combined GPP score metric"}>
          <span>GPP Score</span>
        </Tooltip>
      ),
      sorter: (a, b) => {
        if (showdown)
          return getShowdownGPPValue(b.RosterPosition, b.Salary, b.ProjPts, site) - getShowdownGPPValue(a.RosterPosition, a.Salary, a.ProjPts, site)
        else
          return getNBAGPPValue(b.Salary, b.ProjPts) - getNBAGPPValue(a.Salary, a.ProjPts)
      },
      key: 'gppVal',
      render: (row) => {
        if (authorizedSport) {
          if (showdown) {
            return (
              getShowdownGPPValue(row.RosterPosition, row.Salary, row.ProjPts, site).toFixed(2)
            )
          } else {
            return (
              getNBAGPPValue(row.Salary, row.ProjPts).toFixed(2)
            )
          }
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Ceiling Projection"}>
          <span>Ceil.</span>
        </Tooltip>
      ),
      sorter: (a, b) => {
        return b.Ceiling-a.Ceiling
      },
      key: 'Ceiling',
      render: (row) => {
        if (authorizedSport) {
          return (
            Number(row[`Ceiling`]).toFixed(2)
          )
        } else {
          return <Lock />
        }
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Overwrite our projected ownership here"}>
          <span>User Own</span>
        </Tooltip>
      ),
      dataIndex: 'UserOwn',
      key: 'userOwn',
      render: (text, row) => {
        return (
          <UserProjInput
            onChange={(e) => {
              _onUserOwnChange(row, e, userProjOnChange)
            }}
            value={isNull(text) ? '' : text}
          />
        )
      }

    },
    {
      title: 'Proj Own',
      dataIndex: 'ProjOwn',
      key: 'projOwn',
      sorter: (a, b) => Number(a.ProjOwn) - Number(b.ProjOwn),
      render: (_, row) => {
        if (authorizedSport) {
          return row.HouseOwn && row.UserOwn ? (<span style={{color: "#9a9a9a"}}>{Number(row.HouseOwn).toFixed(2)}</span>) : Number(row.ProjOwn).toFixed(2)
        } else {
          return <Lock />
        }
      },
      className: 'divide'
    }
  ]
})}

const _onChangeMin = (row, MinExp, _onChange) => {
  if (MinExp === ".")
    MinExp = "0."
  const _row = {
    ...row,
    MinExp
  }
  _onChange(_row)
}

const _onChangeMax = (row, e, _onChange) => {
  let MaxExp = e.target.value
  if (MaxExp === ".")
    MaxExp = "0."
  const _row = {
    ...row,
    MaxExp
  }
  _onChange(_row)
}

const _lockRow = (row, _onChange) => {
  const _row = {
    ...row,
    MinExp: 100,
    MaxExp: 100
  }
  _onChange(_row)
}

const _clearRow = (row, _onChange) => {
  const _row = {
    ...row,
    MinExp: 0,
    MaxExp: 0
  }
  _onChange(_row)
}

const ExpInput = styled(Input)`
  width: 60px;
`

const exposureCols = (expOnChange, setWidths, onBoostChange, authorizedSport) => ({
  title: 'Exposure',
  className: 'exposure-cols',
  key: 'exposures',
  children: [
    {
      title: (
        <Tooltip placement="top" title={"Each boost increases or decreases a players points projection by 5%. Max of 5"}>
          <span>Boost</span>
        </Tooltip>
      ),
      dataIndex: 'Boost',
      key: 'boost',
      width: '60px',
      render: (text, row) => {
        return (
          <div>
            <ArrowInput
              
            >
              <UpArrow 
                value={row.Boost || 0}
                onClick={(e) => {
                  e.preventDefault()
                  document.onselectstart = function() { return false; }
                  onBoostChange(row, 'up')
                }}
              />
            </ArrowInput>
            <BoostText>
              {row.Boost || 0}
            </BoostText>
            <ArrowInput
              
            >
              <DownArrow 
                value={row.Boost || 0}
                onClick={(e) => {
                  e.preventDefault()
                  document.onselectstart = function() { return false; }
                  onBoostChange(row, 'down')
                }}
              />
            </ArrowInput>
          </div>
        )
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Player will be in at least this percentage of lineups"}>
          <span>Min Exp</span>
        </Tooltip>
      ),
      dataIndex: 'MinExp',
      key: 'minExp',
      sorter: (a, b) => Number(a.MinExp) - Number(b.MinExp),
      render: (text, row) => {
        return (
          <ExpInput 
            disabled={!authorizedSport} 
            onChange={(e) => {
              const v = e.target.value
              if (Number(v) === 100) {
                const available = isPlayerLockValid(row)

                if (!available) {
                  setHandbuildError(`Unable to add ${row.Name}. Too many players at this position.`)
                  _onChangeMin(row, 0, expOnChange)
                } else {
                  _onChangeMin(row, v, expOnChange)
                }
              } else {
                _onChangeMin(row, v, expOnChange)
              }
            }}
            value={Number(text) > 0 ? text : ''} placeholder={0} 
          />
        )
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Player will be in at most this percentage of lineups"}>
          <span>Max Exp</span>
        </Tooltip>
      ),
      dataIndex: 'MaxExp',
      key: 'maxExp',
      sorter: (a, b) => Number(a.MaxExp) - Number(b.MaxExp),
      render: (text, row) => {
        return (
          <ExpInput disabled={!authorizedSport} onChange={(e) => _onChangeMax(row, e, expOnChange)} value={Number(text) > 0 ? text : ''} placeholder={0} />
        )
      }
    }
  ]
})

const PlayersTable = ({ loading, rows = [], userProjOnChange, expOnChange, permissions, site, showdown, selectPlayerStats, onBoostChange, authorizedSport=true, isPlayerLockValid, setHandbuildError }) => {
  const _setWidths = true

  const allCols = [
    playerDetailsCols(_setWidths, selectPlayerStats, isPlayerLockValid, setHandbuildError, expOnChange),
    teamAndGameInfoCols(_setWidths),
    playerProjectionCols(userProjOnChange, permissions, site, showdown, authorizedSport),
    exposureCols(expOnChange, _setWidths, onBoostChange, authorizedSport)
  ]

  return (
    <StyledTable
      columns={allCols}
      dataSource={rows}
      bordered
      loading={loading}
      size='small'
      rowKey={_row => String(_row.Id)}
      scroll={{ y: 700, x: 1400 }}
      pagination={{
        pageSize: 100
      }}
    />
  )
}

export default PlayersTable

import React from 'react'
import { Input, Row, Col, Tooltip } from 'antd'
import {
  LockOutlined,
  CloseOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  AimOutlined
} from '@ant-design/icons'
import styled from 'styled-components'

import { BoostText, UpArrow, DownArrow, ArrowInput } from '../../../../../components/boost'

import isNull from '../../../../../utils/isNull'

import { overridePrimary, overridePerms, overrideColor } from '../../../../../constants/partner'
import { getColorForValueForImpPts, getColorForValueForOU } from '../../../../../utils/color'
import { getTeamImg } from '../../../../../utils/team-images-loader'
import { StyledTable, PlayerText } from '../../../../../components/shared-styles'

const Lock = () => (
  <Tooltip title="Upgrade for this feature">
    <LockOutlined />
  </Tooltip>
)

const ClickableLock = styled(LockOutlined)`
  :hover {
    color: ${overridePrimary};
  }
`
const ClickableRemove = styled(CloseOutlined)`
  :hover {
    color: ${overridePrimary};
  }
`

const playerDetailsCols =  (setWidths, selectPlayerStats, cheatsheetPlayers, expOnChange, handbuildMode, isPlayerLockValid, setHandbuildError) => ({
  title: 'Player Details',
  key: 'playerDetails',
  children: [
    {
      title: '',
      key: 'lockUnlock',
      width: 60,
      render: (_, row) => {
        return (
          <Row>
            <Col lg={12} md={12} sm={12} xs={24}>
              <ClickableLock
                style={{
                  color: Number(row.MinExp) === 100 ? overridePrimary : overrideColor
                }}
                onClick={() => {
                  const available = isPlayerLockValid(row)

                  if (!available) {
                    setHandbuildError(`Unable to add ${row.Name}. Too many players at this position.`)
                  } else {
                    _lockRow(row, expOnChange)
                  }
                }}
              />
            </Col>
            <Col lg={12} md={12} sm={12} xs={24}>
              <ClickableRemove onClick={() => _clearRow(row, expOnChange)}/>
            </Col>
          </Row>
        )
      }
    },
    {
      title: 'Player Name',
      dataIndex: 'Name',
      key: 'playerName',
      width: setWidths ? 200 : undefined,
      render: (text, row) => {
        return (
          <PlayerText onClick={() => {selectPlayerStats(row)}}>
            {text}
          </PlayerText>
        )
      }
    },
    {
      title: 'Salary',
      dataIndex: 'Salary',
      key: 'salary',
      defaultSortOrder: 'descend',
      sorter: (a, b) => Number(a.Salary) - Number(b.Salary),
    },
    {
      title: 'Pos',
      dataIndex: 'Position',
      key: 'position',
      width: setWidths ? 60 : undefined,
    },
    {
      title: (
        <Tooltip title={'Batting Order'}>
          Bat O.
        </Tooltip>
      ),
      width: 80,
      dataIndex: 'BattingOrder',
      className: 'divide',
      key: 'battingOrder',
      sorter: (a, b) => Number(a.BattingOrder) - Number(b.BattingOrder),
      render: (text) => {
        if (!text || text === 'N/A') return ''

        return text
      }
    }
  ]
})

const teamAndGameInfoCols = (setWidths, showVegas, sport) => {
  let children = [
    {
      title: 'Team',
      dataIndex: 'TeamAbbrev',
      key: 'teamAbbrev',
      width: setWidths ? 60 : undefined,
      render: (text, row) => {
        try {
          return (
            getTeamImg(sport, text)
          )
        } catch (e) {
          console.log(e)
          console.log(text)
          console.log(row)
        }
      }
    },
    {
      title: 'Opp',
      dataIndex: 'Opp',
      key: 'opp',
      width: setWidths ? 60 : undefined,
      render: (text, row) => {
        try {
          return (
            getTeamImg(sport, text)
          )
        } catch (e) {
          console.log(text)
          console.log(row)
        }
      }
    }
  ]

  if (showVegas) {
    children = children.concat([
      {
        title: 'Ven.',
        dataIndex: 'Venue',
        key: 'venue',
        width: setWidths ? 50 : undefined,
      },
      {
        title: (
          <Tooltip placement="top" title={"Projected total points for the game"}>
            <span>OU</span>
          </Tooltip>
        ),
        dataIndex: 'OU',
        width: setWidths ? 60 : undefined,
        key: 'ou',
        sorter: (a, b) => Number(a.OU) - Number(b.OU),
        render: (val) => (
          <div
          style=
            {
              {
                backgroundColor: getColorForValueForOU(val),
                textAlign: 'center',
                opacity: '0.75',
                width: '100%'
              }
            }
          >
            { val }
          </div>
        )
      },
      {
        title: (
          <Tooltip placement="top" title={"Projected amount team will win or lose by (favored team is negative)"}>
            <span>Spread</span>
          </Tooltip>
        ),
        dataIndex: 'Spread',
        key: 'spread',
        sorter: (a, b) => Number(b.Spread) - Number(a.Spread)
      },
      {
        title: (
          <Tooltip placement="top" title={"Projected total points for the team. For DST, it shows the total for the opposing team (lower Imp Pts is better for a DST)"}>
            <span>Imp Pts</span>
          </Tooltip>
        ),
        dataIndex: 'ImpPts',
        width: '70px', 
        key: 'currImpPt',
        sorter: (a, b) => Number(b.ImpPts) - Number(a.ImpPts),
        render: (val) => (
          <div
          style=
            {
              {
                backgroundColor: getColorForValueForImpPts(val),
                textAlign: 'center',
                opacity: '0.75',
                width: '100%'
              }
            }
          >
            { val }
          </div>
        ),
        className: 'divide'
      },
    ])
  }

  return ({
    title: 'Game Details',
    key: 'teamGameInfo',
    children
  })
}

const UserProjInput = styled(Input)`
  width: 60px;
`

const _onUserProjChange = (row, e, _onChange) => {
  let projPts = e.target.value
  if (projPts === ".")
    projPts = "0."
  // Keep track of the original Proj Pts
  const HouseProjPts = (row.HouseProjPts === undefined || row.HouseProjPts === null) ? row.ProjPts : row.HouseProjPts

  let _row
  if (projPts === '') {
      _row = {
        ...row,
        UserProj: '',
        ProjPts: HouseProjPts,
        HouseProjPts
      }
  } else {
    _row = {
      ...row,
      UserProj: projPts,
      ProjPts: projPts,
      HouseProjPts
    }
  }

  _onChange(_row)
}

const _onUserOwnChange = (row, e, _onChange) => {
  let projOwn = e.target.value
  if (projOwn === ".")
    projOwn = "0."
  // Keep track of the original Ownership
  const HouseOwn = row.HouseOwn ? row.HouseOwn : row.ProjOwn

  let _row
  if (projOwn === '') {
    if (HouseOwn) {
      _row = {
        ...row,
        UserOwn: '',
        ProjOwn: HouseOwn,
        HouseOwn
      }
    } else {
      _row = row
    }
  } else {
    _row = {
      ...row,
      UserOwn: projOwn,
      ProjOwn: projOwn,
      HouseOwn
    }
  }

  _onChange(_row)
}

const playerProjectionCols = (userProjOnChange, permissions, site, showdown=false, hasGPPScore) => {
  let children = [{
    title: (
      <Tooltip placement="top" title={"Projected point total"}>
        <span>Proj Pts</span>
      </Tooltip>
    ),
    key: 'dKPts',
    sorter: (a, b) => Number(a.HouseProjPts || a.ProjPts) - Number(b.HouseProjPts || b.ProjPts),
    render: (_, row) => {
      if ((permissions && permissions.opt.nfl.projections)) {
        const HouseProjPts = (row.HouseProjPts === undefined || row.HouseProjPts === null) ? row.ProjPts : row.HouseProjPts
        const pts = HouseProjPts
        return (
          Number(pts).toFixed(2)
        )
      } else {
        return <Lock />
      }
    }
  },
  {
    title: (
      <Tooltip placement="top" title={"Overwrite our projected points here"}>
        <span>User Proj</span>
      </Tooltip>
    ),
    dataIndex: 'UserProj',
    key: 'userProj',
    render: (text, row) => {
      return (<UserProjInput onChange={(e) => _onUserProjChange(row, e, userProjOnChange)} value={isNull(text) ? '' : text} />)
    }

  },
  {
    title: (
      <Tooltip placement="top" title={"Projected points / Salary * 1000"}>
        <span>Proj Value</span>
      </Tooltip>
    ),
    key: 'projval',
    sorter: (a, b) => (Number(a.ProjPts) / Number(a.Salary) * 1000) - (Number(b.ProjPts) / Number(b.Salary) * 1000),
    render: (_, row) => {
      let multiplier = 1000
      if (site === 'ya')
        multiplier = 10

      if (permissions && permissions.opt.nfl.projections) {
        return (
          (Number(row.ProjPts) / Number(row.Salary) * multiplier).toFixed(2)
        )
      } else {
        return <Lock />
      }
    }
  },
  {
    title: (
      <Tooltip placement="top" title={"Ceiling Projection"}>
        <span>Ceil.</span>
      </Tooltip>
    ),
    sorter: (a, b) => {
      return b.Ceiling-a.Ceiling
    },
    key: 'Ceiling',
    render: (row) => {
      if ((permissions && permissions.opt.nfl.projections)) {
        return (
          Number(row[`Ceiling`] || 0).toFixed(2)
        )
      } else {
        return <Lock />
      }
    }
  }]

  if (hasGPPScore) {
    children.push({
      title: (
        <Tooltip placement="top" title={"Combined GPP score metric"}>
          <span>GPP Score</span>
        </Tooltip>
      ),
      sorter: (a, b) => (
        b.GPPScore - a.GPPScore
      ),
      dataIndex: 'GPPScore',
      key: 'GPPScore',
      render: (text, row) => {
        if (!permissions.opt.nfl.projections) return <Lock />
        return Number(text).toFixed(2)
      }
    },)
  }

  children = children.concat([
    {
      title: (
        <Tooltip placement="top" title={"Overwrite our projected ownership here"}>
          <span>User Own</span>
        </Tooltip>
      ),
      dataIndex: 'UserOwn',
      key: 'userOwn',
      render: (text, row) => {
        return (
          <UserProjInput
            onChange={(e) => {
              _onUserOwnChange(row, e, userProjOnChange)
            }}
            value={isNull(text) ? '' : text}
          />
        )
      }

    },
    {
      title: 'Proj Own',
      dataIndex: 'ProjOwn',
      key: 'projOwn',
      sorter: (a, b) => Number(a.ProjOwn) - Number(b.ProjOwn),
      render: (_, row) => {
        if ((permissions && permissions.opt.nfl.ownership)) {
          return row.HouseOwn && row.UserOwn ? (<span style={{color: "#9a9a9a"}}>{Number(row.HouseOwn).toFixed(2)}</span>) : Number(row.ProjOwn).toFixed(2)
        } else {
          return <Lock />
        }
      },
      className: 'divide'
    }
  ])

  return ({
    title: 'Player Projections',
    key: 'playerProjections',
    children
  })
}

const _onChangeMin = (row, MinExp, _onChange) => {
  if (MinExp === ".")
    MinExp = "0."
  const _row = {
    ...row,
    MinExp
  }
  _onChange(_row)
}

const _onChangeMax = (row, e, _onChange) => {
  let MaxExp = e.target.value
  if (MaxExp === ".")
    MaxExp = "0."
  const _row = {
    ...row,
    MaxExp
  }
  _onChange(_row)
}

const _lockRow = (row, _onChange) => {
  const _row = {
    ...row,
    MinExp: 100,
    MaxExp: 100
  }
  _onChange(_row)
}

const _clearRow = (row, _onChange) => {
  const _row = {
    ...row,
    MinExp: 0,
    MaxExp: 0
  }
  _onChange(_row)
}

const ExpInput = styled(Input)`
  width: 60px;
`

const exposureCols = (expOnChange, setWidths, onBoostChange, isPlayerLockValid, setHandbuildError) => ({
  title: 'Exposure',
  className: 'exposure-cols',
  key: 'exposures',
  children: [
    {
      title: (
        <Tooltip placement="top" title={"Each boost increases or decreases a players points projection by 5%. Max of 5"}>
          <span>Boost</span>
        </Tooltip>
      ),
      dataIndex: 'Boost',
      key: 'boost',
      width: '60px',
      render: (text, row) => {
        return (
          <div>
            <ArrowInput
              
            >
              <UpArrow 
                value={row.Boost || 0}
                onClick={(e) => {
                  e.preventDefault()
                  document.onselectstart = function() { return false; }
                  onBoostChange(row, 'up')
                }}
              />
            </ArrowInput>
            <BoostText>
              {row.Boost || 0}
            </BoostText>
            <ArrowInput
              
            >
              <DownArrow 
                value={row.Boost || 0}
                onClick={(e) => {
                  e.preventDefault()
                  document.onselectstart = function() { return false; }
                  onBoostChange(row, 'down')
                }}
              />
            </ArrowInput>
          </div>
        )
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Player will be in at least this percentage of lineups"}>
          <span>Min Exp</span>
        </Tooltip>
      ),
      dataIndex: 'MinExp',
      key: 'minExp',
      sorter: (a, b) => Number(a.MinExp) - Number(b.MinExp),
      render: (text, row) => {
        return (
          <ExpInput 
            onChange={(e) => {
              const v = e.target.value
              if (Number(v) === 100) {
                const available = isPlayerLockValid(row)

                if (!available) {
                  setHandbuildError(`Unable to add ${row.Name}. Too many players at this position.`)
                  _onChangeMin(row, 0, expOnChange)
                } else {
                  _onChangeMin(row, v, expOnChange)
                }
              } else {
                _onChangeMin(row, v, expOnChange)
              }
            }}
            value={Number(text) > 0 ? text : ''} 
            placeholder={0} 
          />
        )
      }
    },
    {
      title: (
        <Tooltip placement="top" title={"Player will be in at most this percentage of lineups"}>
          <span>Max Exp</span>
        </Tooltip>
      ),
      dataIndex: 'MaxExp',
      key: 'maxExp',
      sorter: (a, b) => Number(a.MaxExp) - Number(b.MaxExp),
      render: (text, row) => {
        return (
          <ExpInput onChange={(e) => _onChangeMax(row, e, expOnChange)} value={Number(text) > 0 ? text : ''} placeholder={0} />
        )
      }
    }
  ]
})

const MinWidth = 1526

const PlayersTable = ({ loading, rows = [], userProjOnChange, expOnChange, permissions, site, showdown, selectPlayerStats, onBoostChange, handbuildMode, availablePositions, setHandbuildError, isPlayerLockValid, cheatsheetPlayers = [], showVegas=true, sport, hasGPPScore=false }) => {
  const _setWidths = true

  const allCols = [
    playerDetailsCols(_setWidths, selectPlayerStats, cheatsheetPlayers, expOnChange, handbuildMode, isPlayerLockValid, setHandbuildError),
    teamAndGameInfoCols(_setWidths, showVegas, sport),
    playerProjectionCols(userProjOnChange, permissions, site, showdown, hasGPPScore),
    exposureCols(expOnChange, _setWidths, onBoostChange, isPlayerLockValid, setHandbuildError)
  ]

  return (
    <StyledTable
      columns={allCols}
      dataSource={rows}
      pagination={{ 
        defaultPageSize: 100 
      }}
      bordered
      loading={loading}
      size='small'
      rowKey={_row => String(_row.Id)}
      scroll={{ y: 700, x: 1380 }}
    />
  )
}

export default PlayersTable
